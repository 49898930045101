import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import content from '../assets/sliderContent';
import { Carousel } from 'react-bootstrap';
import next from '../images/nextarrow.svg';
import '../components/css/carousel.css';
import * as worksStyles from '../components/css/works.module.css';
import * as layoutStyles from '../components/css/layout.module.css';

const items = content.map((el) => el.carousel);
const description = content.map((el) => el.description);
const skills = content.map((el) => el.skills);

const About = (props) => {
  // If entering from works item, don't use initial scale transformation in carousel
  var scale = 0;
  if (props.location.state) {
    scale = props.location.state.item === undefined ? 0 : 1;
  }

  const [activeIndex, setActiveIndex] = useState(2);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const prevArrow = (
    <img style={{ transform: 'rotate(180deg)' }} src={next} alt='' />
  );
  const nextArrow = <img src={next} alt='' />;

  return (
    <motion.div className={worksStyles.worksContainer}>
      <motion.div
        className={worksStyles.worksCarousel}
        initial={{ scale: scale }}
        animate={{ scale: 1, transition: { duration: 0.5 } }}
      >
        <Carousel
          activeIndex={activeIndex}
          onSelect={handleSelect}
          prevIcon={prevArrow}
          nextIcon={nextArrow}
          interval={null}
        >
          {items.map((el, i) => (
            <Carousel.Item key={i}>{el}</Carousel.Item>
          ))}
        </Carousel>
        {skills[activeIndex]}
      </motion.div>
      <div
        className={`${worksStyles.worksDescription} ${layoutStyles.textContainer}`}
      >
        <AnimatePresence exitBeforeEnter>
          {description[activeIndex]}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};
export default About;
