import React from 'react';
import { motion } from 'framer-motion';
import expand from '../images/expand.svg';

const expandStyle = {
  width: 29,
  height: 29,
  position: 'absolute',
  top: 5,
  right: 5,
  zIndex: 999,
  cursor: 'pointer',
};

const CarouselItem = (props) => {
  return (
    <motion.div
      style={{ position: 'relative' }}
      layoutId={`item-${props.index}`}
    >
      <a
        href={
          props.index === '0'
            ? 'https://mernstackrestaurant.netlify.app/'
            : props.index === '1'
            ? 'https://conservas.herokuapp.com/'
            : 'https://fragment-theta.vercel.app/'
        }
        target='_blank'
        rel='noreferrer'
      >
        <img src={expand} style={expandStyle} alt='link' />
      </a>

      {props.children}
    </motion.div>
  );
};

export default CarouselItem;
