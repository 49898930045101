import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import CarouselItem from '../components/carouseItem';
import { Text } from '../components/text';
import * as skillStyles from '../components/css/skills.module.css';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const sliderContent = [
  {
    carousel: (
      <CarouselItem index={'0'}>
        <StaticImage
          src='../images/confusion.png'
          alt='restaurant slide'
          placeholder='dominantColor'
          width={880}
          style={{ filter: 'var(--grayscale)' }}
        />
      </CarouselItem>
    ),

    skills: (
      <motion.div
        className={skillStyles.skillIcons}
        key='rest'
        variants={container}
        initial='hidden'
        animate='show'
      >
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/html5.svg'
            title='html5'
            alt='html5'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/css3.svg'
            title='css3'
            alt='css3'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/javascript.svg'
            title='javascript'
            alt='javascript'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/react.png'
            title='react'
            alt='react'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/redux.svg'
            title='redux'
            alt='redux'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/nodejs.png'
            alt='nodejs'
            title='nodejs'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/mongodb.png'
            title='mongodb'
            alt='mongodb'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
      </motion.div>
    ),

    description: (
      <motion.div
        key='confusion'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2, delay: 0.1 } }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
      >
        <h3>
          <Text tid='confusion_title' />
        </h3>

        <>
          <Text tid='confusion_description' />
        </>
      </motion.div>
    ),
  },
  {
    carousel: (
      <CarouselItem index={'1'}>
        <StaticImage
          src='../images/conservas.png'
          alt='conservas slide'
          placeholder='dominantColor'
          width={880}
          style={{ filter: 'var(--grayscale)' }}
        />
      </CarouselItem>
    ),

    skills: (
      <motion.div
        className={skillStyles.skillIcons}
        key='cons'
        variants={container}
        initial='hidden'
        animate='show'
      >
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/html5.svg'
            title='html5'
            alt='html5'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/css3.svg'
            title='css3'
            alt='css3'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/javascript.svg'
            title='javascript'
            alt='javascript'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/bootstrap-4.svg'
            title='bootstrap'
            alt='bootstrap'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/php.svg'
            title='php'
            alt='php'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/mysql.svg'
            title='mysql'
            alt='mysql'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
      </motion.div>
    ),

    description: (
      <motion.div
        key='conservas'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2, delay: 0.1 } }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
      >
        <h3>
          <Text tid='conservas_title' />
        </h3>

        <>
          <Text tid='conservas_description' />
        </>
      </motion.div>
    ),
  },
  {
    carousel: (
      <CarouselItem index={'2'}>
        <StaticImage
          src='../images/fragment.png'
          alt='fragment slide'
          placeholder='dominantColor'
          width={880}
          style={{ filter: 'var(--grayscale)' }}
        />
      </CarouselItem>
    ),

    skills: (
      <motion.div
        className={skillStyles.skillIcons}
        key='frg'
        variants={container}
        initial='hidden'
        animate='show'
      >
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/typescript.svg'
            title='typescript'
            alt='typescript'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/tailwind.svg'
            title='tailwind'
            alt='tailwind'
            height={40}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span
          variants={item}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <StaticImage
            src='../images/icons/nextjs.svg'
            title='nextjs'
            alt='nextjs'
            height={25}
            quality={100}
            style={{
              filter: 'var(--grayscale)',
            }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/react-query.svg'
            title='react query'
            alt='react query'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/trpc.svg'
            title='trpc'
            alt='trpc'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
        <motion.span variants={item}>
          <StaticImage
            src='../images/icons/prisma.svg'
            title='prisma'
            alt='prisma'
            height={50}
            quality={100}
            style={{ filter: 'var(--grayscale)' }}
          />
        </motion.span>
      </motion.div>
    ),

    description: (
      <motion.div
        key='conservas'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2, delay: 0.1 } }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
      >
        <h3>
          <Text tid='fragment_title' />
        </h3>

        <>
          <Text tid='fragment_description' />
        </>
      </motion.div>
    ),
  },
];

export default sliderContent;
